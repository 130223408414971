<div class="wp-page-wrap" [ngClass]="{ 'wide-padding': widePadding }">
  <div class="wp-page">
    <div class="wp-page__section top">
      <ng-content select="wp-page-top"></ng-content>
    </div>

    <div class="wp-page__section body customScroll">
      <ng-content select="wp-page-body"></ng-content>
    </div>

    <div class="wp-page__section bottom">
      <ng-content select="wp-page-bottom"></ng-content>
    </div>
  </div>
</div>
