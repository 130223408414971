import { Directive, EventEmitter, inject, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { SelectionState } from '@rootTypes';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { CheckboxGroupInterface, CheckboxItemInterface } from './types';
import { CheckboxItem, CheckboxItemData } from './checkbox-item';

@Directive({
  selector: '[wpCheckboxGroup]',
  exportAs: 'wpCheckboxGroup',
})
export class CheckboxGroupDirective implements OnChanges, OnDestroy, CheckboxGroupInterface {
  @Input() public groupId: string | null = null;
  @Input() public selected: string[];
  // pass, if child checkbox directives have not been rendered yet
  @Input() public checkboxItems: CheckboxItemData[];
  @Output() public selectedChanged = new EventEmitter<string[]>();

  public readonly groupState$: Observable<SelectionState>;
  private readonly items$ = new BehaviorSubject<{ [id: string]: CheckboxItemInterface }>({});
  private readonly parent?: CheckboxGroupDirective;
  constructor() {
    try {
      this.parent = inject(CheckboxGroupDirective, { skipSelf: true });
    } catch (e) {
      // do nothing, there's no parent group directive, so consider this to be the top-level one
    }
    this.groupState$ = this.items$.pipe(
      debounceTime(200),
      switchMap((checkboxes) => {
        if (Object.keys(checkboxes).length === 0) {
          return of(SelectionState.NONE_SELECTED);
        }
        const checked$ = combineLatest(Object.values(checkboxes).map((c) => c.checked$));
        const disabled$ = combineLatest(Object.values(checkboxes).map((c) => c.disabled$));
        return combineLatest({ checked: checked$, disabled: disabled$ }).pipe(
          map(({ checked, disabled }) => {
            return this.getGroupSelectionState(checked, disabled);
          }),
        );
      }),
      distinctUntilChanged(),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected && !(this.parent && this.parent.groupId === this.groupId)) {
      this.doSetSelectedIds(this.selected);
    }
    if (changes.checkboxItems) {
      const prevValue: CheckboxItemData[] = changes.checkboxItems.previousValue;
      (prevValue || []).forEach((v) => this.removeItem(v.id));
      const newValue: CheckboxItemData[] = changes.checkboxItems.currentValue;
      (newValue || []).forEach((v) => {
        this.addItem(new CheckboxItem(v));
      });
    }
  }

  ngOnDestroy(): void {
    if (this.checkboxItems?.length > 0) {
      this.checkboxItems.forEach((checkbox) => {
        this.removeItem(checkbox.id);
      });
    }
  }
  emitChange(): void {
    if (this.parent && this.groupId === this.parent.groupId) {
      this.parent.emitChange();
    } else {
      this.doEmitSelected();
    }
  }

  toggle(): void {
    const checkboxes = Object.values(this.items$.getValue());
    const checked = checkboxes.map((c) => c.isChecked());
    const disabled = checkboxes.map((c) => c.isDisabled());
    const groupState = this.getGroupSelectionState(checked, disabled);
    if (groupState === SelectionState.NONE_SELECTED) {
      checkboxes.forEach((c) => c.setChecked(true));
    } else {
      checkboxes.forEach((c) => c.setChecked(false));
    }
    this.emitChange();
  }

  selectAll(): void {
    const checkboxes = Object.values(this.items$.getValue());
    checkboxes.forEach((c) => c.setChecked(true));
    this.emitChange();
  }

  clearAll(): void {
    const checkboxes = Object.values(this.items$.getValue());
    checkboxes.forEach((c) => c.setChecked(false));
    this.emitChange();
  }

  /*
   * These methods will be called by child directives
   */
  addItem(item: CheckboxItemInterface): void {
    const value = this.items$.getValue();
    const newValue = {
      ...value,
      [item.id]: item,
    };
    this.items$.next(newValue);
    if (this.parent && this.parent.groupId == this.groupId) {
      this.parent.addItem(item);
    } else {
      const itemChecked = (this.selected || []).includes(item.id);
      item.setChecked(itemChecked);
    }
  }
  removeItem(itemId: string): void {
    const value = this.items$.getValue();
    const newValue = { ...value };
    delete newValue[itemId];
    this.items$.next(newValue);
    if (this.parent && this.parent.groupId === this.groupId) {
      this.parent.removeItem(itemId);
    }
  }

  private doSetSelectedIds(ids: string[]): void {
    const checkboxes = this.items$.getValue();
    const idsMap = (ids ?? []).reduce((p, c) => ({ ...p, [c]: true }), {});
    const newValue = { ...checkboxes };
    Object.keys(newValue).forEach((id) => {
      newValue[id].setChecked(!!idsMap[id]);
    });
  }

  private doEmitSelected(): void {
    const checkboxes = this.items$.getValue();
    const selected = Object.values(checkboxes)
      .filter((c) => c.isChecked())
      .map((c) => c.id);
    this.selectedChanged.emit(selected);
  }

  private getGroupSelectionState(checked: boolean[], disabled: boolean[]): SelectionState {
    if (!checked.length) {
      return SelectionState.NONE_SELECTED;
    }
    let hasAtLeastOneChecked = false;
    let hasAtLeastOneUnchecked = false;
    for (let i = 0; i < checked.length; i++) {
      if (checked[i]) {
        hasAtLeastOneChecked = true;
        if (hasAtLeastOneUnchecked) {
          return SelectionState.PARTIALLY_SELECTED;
        }
      } else if (!disabled[i]) {
        hasAtLeastOneUnchecked = true;
        if (hasAtLeastOneChecked) {
          return SelectionState.PARTIALLY_SELECTED;
        }
      }
    }
    return hasAtLeastOneChecked ? SelectionState.ALL_SELECTED : SelectionState.NONE_SELECTED;
  }
}
