import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { SmartInputAddressMap } from '../../models';
import { GeocoderService } from '../../../../core/services';
import { Address, getAddressId } from '../../dependencies';
import { MapMarker } from '@rootTypes';
import { iconPaths } from '@rootTypes/utils';

const defaultMapOptions = () => ({
  mapTypeId: google.maps.MapTypeId.ROADMAP,
  disableDefaultUI: false,
  fullscreenControl: false,
  mapTypeControlOptions: { mapTypeIds: [] },
  streetViewControl: false,
});

const disabledMapOptions = () => ({
  draggable: false,
  zoomControl: false,
  scrollwheel: false,
  disableDoubleClickZoom: true,
});

const enabledMapOptions = () => ({
  draggable: true,
  zoomControl: true,
  scrollwheel: true,
  disableDoubleClickZoom: true,
});

@Component({
  selector: 'wp-smart-input-address-map',
  templateUrl: './smart-input-address-map.component.html',
  styleUrls: ['./smart-input-address-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputAddressMapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public model: SmartInputAddressMap;

  public mapStyles: { width: string; height: string };
  public cursorPosition: { top: string; left: string };
  public cursorIcon = iconPaths.MAP_PIN_BLUE;
  public cursorWidthPx = 34;
  public cursorHeightPx = 55;
  public isTooltipShown = true;

  @ViewChild('map') private mapRef: ElementRef;
  private map: google.maps.Map;
  private mapListeners: google.maps.MapsEventListener[] = [];
  private additionalMarkers: google.maps.Marker[] = [];
  private selectedAddressId: string;
  private sub = new Subscription();

  /**
   * Prevents moving the map center when the geocode result is fetched.
   * Note, geocode result contains an approximate
   * address that could differ from the map center.
   */
  private geocodeRequested: boolean;

  constructor(private geocoder: GeocoderService) {}

  public ngOnInit(): void {
    this.setCursorPosition();
    this.mapStyles = {
      width: this.model.mapWidthPx + 'px',
      height: this.model.mapHeightPx + 'px',
    };
    this.model.address.control.registerOnDisabledChange((isDisabled) => {
      if (!this.map) {
        return;
      }
      if (isDisabled) {
        this.map.setOptions({
          center: this.map.getCenter(),
          ...defaultMapOptions(),
          ...disabledMapOptions(),
        });
      } else {
        this.map.setOptions({
          center: this.map.getCenter(),
          ...defaultMapOptions(),
          ...enabledMapOptions(),
        });
      }
    });
  }

  public ngAfterViewInit(): void {
    const address = this.model.getValue();
    let center: { lat: number; lng: number };
    if (address) {
      center = address?.geometry?.location;
    } else if (this.model.centerMapOn) {
      center = this.model.centerMapOn.geometry.location;
    } else {
      // San Jose, California
      center = { lat: 37.3382, lng: -121.8863 };
    }
    this.map = new google.maps.Map(this.mapRef.nativeElement, {
      center,
      zoom: this.model.initialZoom,
      ...defaultMapOptions,
      ...(this.model.address.control.disabled ? disabledMapOptions() : enabledMapOptions()),
    });

    const dragendListener = google.maps.event.addListener(this.map, 'dragend', this.setAddressFromMapCenter.bind(this));
    this.mapListeners.push(dragendListener);

    this.selectedAddressId = getAddressId(this.model.getValue());
    this.setMapCenterOnAddressChange();
    const addressChangeSub = this.model.address.control.valueChanges.subscribe((address) => {
      if (getAddressId(address) !== this.selectedAddressId) {
        this.setMapCenterOnAddressChange(this.model.addressChangeZoom);
      }
    });
    this.sub.add(addressChangeSub);

    const markerSub = this.model.markers$.subscribe((markers) => {
      this.setAdditionalMarkers(markers);
    });
    this.sub.add(markerSub);
  }

  public ngOnDestroy(): void {
    this.mapListeners.forEach((listener) => listener.remove());
    this.sub.unsubscribe();
  }

  public hideTooltip(): void {
    this.isTooltipShown = false;
  }

  private async setAddressFromMapCenter(): Promise<void> {
    let address: Address;
    if (this.model.noGeocoder) {
      address = {
        geometry: {
          location: this.map.getCenter(),
        },
      } as any as Address;
    } else {
      this.geocodeRequested = true;
      address = await this.geocoder.getAddressFromLocation(this.map.getCenter());
    }
    this.selectedAddressId = getAddressId(address);
    this.model.address.setValue(address);
  }

  private setCursorPosition(): void {
    const top = `${this.model.mapHeightPx / 2 - this.cursorHeightPx}px`;
    const left = `${this.model.mapWidthPx / 2 - this.cursorWidthPx / 2}px`;
    this.cursorPosition = { top, left };
  }

  private setMapCenterOnAddressChange(zoom?: number): void {
    if (this.geocodeRequested) {
      this.geocodeRequested = false;
      return;
    }
    const address = this.model.getValue();
    if (!address) {
      return;
    }
    this.map.setCenter(address?.geometry?.location);
    if (typeof zoom === 'number') {
      this.map.setZoom(zoom);
    }
  }

  private setAdditionalMarkers(markers?: MapMarker[]): void {
    if (!markers || markers.length === 0) {
      return;
    }
    this.additionalMarkers.forEach((gm) => gm.setMap(null));
    this.additionalMarkers.length = 0;
    markers.forEach((marker) => this.addMarkerToMap(marker));
  }

  private addMarkerToMap(marker: MapMarker): void {
    const width = marker.width || 16;
    const widthHalf = Math.floor(width / 2);
    const height = marker.height || 16;
    const image = {
      url: marker.url,
      scaledSize: new google.maps.Size(width, height),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(widthHalf, height),
    };
    const gMarker = new google.maps.Marker({
      position: { lat: marker.location.lat, lng: marker.location.lng },
      icon: image,
      map: this.map,
      zIndex: 8000,
    });
    this.additionalMarkers.push(gMarker);

    if (marker.tooltip && marker.tooltip.length) {
      const infoWindow = new google.maps.InfoWindow({
        content: `${marker.tooltip}`,
      });
      gMarker.addListener('mouseover', () => {
        infoWindow.open(this.map, gMarker);
      });
      gMarker.addListener('mouseout', () => {
        infoWindow.close();
      });
    }
  }

  private fitMapToMarkers(): void {
    const value = this.map?.getCenter();
    const bounds = value ? new google.maps.LatLngBounds(value) : new google.maps.LatLngBounds();
    for (let i = 0; i < this.additionalMarkers.length; i++) {
      bounds.extend(this.additionalMarkers[i].getPosition());
    }
    this.map.fitBounds(bounds);
  }

  private isMapWithinBounds(map: google.maps.Map, bounds: google.maps.LatLngBounds): boolean {
    const mapBounds = map.getBounds();
    if (!mapBounds) {
      return true;
    }
    return mapBounds.contains(bounds.getNorthEast()) && mapBounds.contains(bounds.getSouthWest());
  }
}
