import { Injectable, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  of,
  retry,
  Subscription,
  switchMap,
  tap,
  timer,
} from 'rxjs';
import { EntityFilterOptions } from 'src/app/api/endpoints/entity-filter';
import { setPortalEntityInfo } from '../../data/common/store/actions/portal-entities-info.actions';
import { EntityFilterApiService } from './entity-filter-api.service';
import * as fromTypes from './types';

const pageSize = 10;

@Injectable({
  providedIn: 'root',
})
export class EntityFilterStoreService implements fromTypes.EntityFilterStore {
  isEmptySearchAllowed = signal<boolean>(false);

  private state$: BehaviorSubject<fromTypes.EntityFilterState> = new BehaviorSubject<fromTypes.EntityFilterState>(
    fromTypes.initialEntityFilterState,
  );

  private mainSubscription: Subscription;

  constructor(
    private api: EntityFilterApiService,
    private store: Store,
  ) {
    this.setMainSubscription();
  }

  setEntityStatus(status: fromTypes.EntityStatus): void {
    this.state$.next({
      ...this.state$.value,
      status,
    });
  }

  setFilterOptions(options: EntityFilterOptions): void {
    this.state$.next({
      ...this.state$.value,
      filterOptions: { ...(options || {}) },
    });
  }

  error$(): Observable<any> {
    return this.state$.asObservable().pipe(
      map((state) => state.results.error),
      distinctUntilChanged(),
    );
  }

  hasNext$(): Observable<boolean> {
    return this.state$.asObservable().pipe(
      map((state) => {
        return !!(state.searchTerm || this.isEmptySearchAllowed()) && !state.results.pageLoading && !!state.nextSkip;
      }),
    );
  }

  empty$(): Observable<boolean> {
    return this.state$.asObservable().pipe(
      map((state) => {
        return (
          !!(state.searchTerm && state.searchTerm.length) &&
          !state.results.pageLoading &&
          state.results.items.length === 0
        );
      }),
    );
  }

  dispose(): void {
    if (this.mainSubscription) {
      this.mainSubscription.unsubscribe();
    }
  }

  items$(): Observable<fromTypes.PortalEntity[]> {
    return this.state$.asObservable().pipe(
      map((state) => state.results.items),
      distinctUntilChanged(),
    );
  }

  dropdownOpen$(): Observable<boolean> {
    return this.state$.asObservable().pipe(
      map((state) => state.isDropdownOpen),
      distinctUntilChanged(),
    );
  }

  closeDropdown(): void {
    this.state$.next({
      ...this.state$.value,
      isDropdownOpen: false,
    });
  }

  loadNextPage(): void {
    const currentState = this.state$.value;
    if (currentState.nextSkip) {
      const { currentPage } = currentState;
      this.state$.next({
        ...currentState,
        currentPage: currentPage + 1,
      });
    }
  }

  openDropdown(): void {
    this.state$.next({
      ...this.state$.value,
      isDropdownOpen: true,
    });
  }

  pageLoading(): Observable<boolean> {
    return this.state$.asObservable().pipe(
      map((state) => state.results.pageLoading),
      distinctUntilChanged(),
    );
  }

  search(searchTerm: string, openDropdown: boolean = true): void {
    this.state$.next({
      ...this.state$.value,
      currentPage: 0,
      nextSkip: 0,
      isDropdownOpen: openDropdown,
      results: {
        ...this.state$.value.results,
        pageLoading: true,
        items: [],
        error: null,
      },
      searchTerm,
    });
  }

  /**
   * Will search by @param searchByTypes only.
   * If omitted, will search across all entities
   */
  setSearchByType(searchByType: fromTypes.PortalEntityType): void {
    this.state$.next({
      ...this.state$.value,
      searchByType,
    });
  }

  private setMainSubscription(): void {
    const searchTermChanges$ = this.state$.asObservable().pipe(
      map((state) => state.searchTerm),
      distinctUntilChanged(),
    );
    const pageChanges$ = this.state$.asObservable().pipe(
      map((state) => state.currentPage),
      distinctUntilChanged(),
    );

    this.mainSubscription = searchTermChanges$
      .pipe(
        debounceTime(300),
        switchMap((searchTerm) => {
          return pageChanges$.pipe(
            tap(() => this.emitPageLoading(true)),
            switchMap((currentPage) => this.doSearch()),
            retry(1),
            tap((res) => {
              this.store.dispatch(setPortalEntityInfo({ entities: res.results }));
            }),
            catchError((err, caught) => {
              return of({ searchTerm, results: [], nextSkip: 0, error: err });
            }),
          );
        }),
      )
      .subscribe((res) => this.onSearchResult(res));
  }

  private emitPageLoading(isLoading: boolean): void {
    this.state$.next({
      emitLine: 154,
      ...this.state$.value,
      results: {
        ...this.state$.value.results,
        pageLoading: isLoading,
      },
    });
  }

  private doSearch(): Observable<fromTypes.SearchResponseWithSearhTerm> {
    const { searchTerm, nextSkip, searchByType, filterOptions, status } = this.state$.value;
    // no more items
    if (nextSkip === null || ((searchTerm === null || searchTerm.length === 0) && !this.isEmptySearchAllowed())) {
      return timer(200).pipe(switchMap(() => of({ nextSkip: null, results: [], searchTerm })));
    }
    return this.api
      .entityFilter(searchTerm, nextSkip, pageSize, searchByType, status, filterOptions)
      .pipe(map((res) => ({ ...res, searchTerm })));
  }

  private onSearchResult(res: fromTypes.SearchResponseWithSearhTerm | null): void {
    if (res) {
      const { nextSkip, results, searchTerm, error } = res;
      const prevState = this.state$.value;
      if (prevState.searchTerm !== searchTerm) {
        return;
      }
      if (error) {
        console.log(error);
        this.state$.next({
          emitLine: 182,
          ...prevState,
          results: {
            ...prevState.results,
            items: [],
            pageLoading: false,
            error,
          },
        });
      } else {
        this.state$.next({
          emitLine: 182,
          ...prevState,
          nextSkip,
          results: {
            ...prevState.results,
            items: [...prevState.results.items, ...results] as fromTypes.PortalEntity[],
            pageLoading: false,
            error: null,
          },
        });
      }
    }
  }
}
