import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, firstValueFrom } from 'rxjs';
import { Address, MapBounds, WebErrorDetails } from '@rootTypes';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  GOOGLE_AUTOCOMPLETE_DEBOUNCE,
  GOOGLE_AUTOCOMPLETE_DEFAULT_BIAS,
} from '../../../google-map/google-place-autocomplete/utils/config';
import { ZumGeocoderService } from '../../../../core/services/zum-geocoder.service';
import { addressToApiAddress } from '../utils/addressV2-to-api-address';
import { ApiAddress } from '../../../../api/endpoints/common';

export type AddressAutocompleteOption = {
  placeId?: string;
  formatted_address: string;
  value?: ApiAddress;
};

@Injectable()
export class GooglePlaceAutocompleteCustomUIStore {
  public isLoading$: Observable<boolean>;
  public isEmpty$: Observable<boolean>;
  public options$: Observable<AddressAutocompleteOption[]>;
  public loadError$: Observable<WebErrorDetails | null>;

  private inputValue$$ = new BehaviorSubject<string>(null);
  private districtId$$ = new BehaviorSubject<string>(null);
  private isLoading$$ = new BehaviorSubject<boolean>(false);
  private loadError$$ = new BehaviorSubject<WebErrorDetails | null>(null);
  private options$$ = new BehaviorSubject<AddressAutocompleteOption[]>([]);
  private lastSelectedAddress: Address | null = null;
  private currentGoogleAutocompleteBias: MapBounds = GOOGLE_AUTOCOMPLETE_DEFAULT_BIAS;

  constructor(private zumGeocoderService: ZumGeocoderService) {
    this.isLoading$ = this.isLoading$$.asObservable();
    this.options$ = this.options$$.asObservable();
    this.loadError$ = this.loadError$$.asObservable();
    this.isEmpty$ = combineLatest([this.isLoading$$, this.options$$]).pipe(
      map(([loading, options]) => !loading && !options?.length),
    );
    combineLatest([this.inputValue$$, this.districtId$$])
      .pipe(
        tap(([v]) => {
          this.loadError$$.next(null);
          if (v?.length) {
            this.isLoading$$.next(true);
          } else {
            this.isLoading$$.next(false);
            this.options$$.next([]);
          }
        }),
        filter(([v]) => !!v?.length),
        debounceTime(GOOGLE_AUTOCOMPLETE_DEBOUNCE),
        switchMap(([v, districtId]) => this.getOptionsForInput(v, districtId)),
        filter(({ inputValue }) => inputValue === this.inputValue$$.value),
        tap(({ options, error }) => {
          this.isLoading$$.next(false);
          this.options$$.next(options);
          this.loadError$$.next(error ?? null);
        }),
        takeUntilDestroyed(),
      )
      .subscribe({
        complete: () => this.onDestroy(),
      });
  }

  public setGoogleAutocompleteBias(bounds: MapBounds): void {
    this.currentGoogleAutocompleteBias = bounds ?? GOOGLE_AUTOCOMPLETE_DEFAULT_BIAS;
  }

  public getLastSelectedAddress(): Address | null {
    return this.lastSelectedAddress;
  }

  public setLastSelectedAddress(address: Address | null): void {
    this.lastSelectedAddress = address;
  }

  public resetSearch(): void {
    this.inputValue$$.next(null);
    this.options$$.next(null);
    this.isLoading$$.next(false);
    this.loadError$$.next(null);
  }

  public onInputValueChanged(value: string): void {
    this.inputValue$$.next(value);
  }

  public onDistrictIdSet(districtId: string | null): void {
    this.districtId$$.next(districtId);
  }

  /**
   * Use this method to get address from option on click
   * @param option
   */
  public async onOptionClick(option: AddressAutocompleteOption): Promise<Address> {
    if (option.value) {
      return option.value;
    }

    if ('placeId' in option) {
      const response = await firstValueFrom(
        this.zumGeocoderService.getAddressInfoByPlaceId({ placeId: option.placeId }),
      );
      this.lastSelectedAddress = addressToApiAddress(response.address, option.formatted_address);
      return addressToApiAddress(response.address, option.formatted_address);
    }

    return null;
  }

  private async getOptionsForInput(
    inputValue: string | null,
    districtId: string | null,
  ): Promise<{
    inputValue: string | null;
    options: AddressAutocompleteOption[];
    error?: WebErrorDetails;
  }> {
    try {
      const options = await this.getOptionsFromPlacePredictions(inputValue, districtId);
      return {
        options,
        inputValue,
      };
    } catch (error) {
      return {
        inputValue,
        options: [],
        error: error?.message ?? error,
      };
    }
  }

  private async getOptionsFromPlacePredictions(
    inputValue: string | null,
    districtId: string | null,
  ): Promise<AddressAutocompleteOption[]> {
    const locationBias = {
      southWest: this.currentGoogleAutocompleteBias.southWest,
      northEast: this.currentGoogleAutocompleteBias.northEast,
    };
    if (!inputValue) {
      return [];
    }

    const response = await firstValueFrom(
      this.zumGeocoderService.geocode({
        addressQuery: {
          query: inputValue,
          bias: locationBias,
        },
        districtId,
      }),
    );

    return response.results
      .map((result) => {
        if ('place' in result) {
          return {
            placeId: result.place.placeId,
            formatted_address: result.label,
          } satisfies AddressAutocompleteOption;
        } else if ('address' in result) {
          return {
            value: addressToApiAddress(result.address, result.label),
            formatted_address: result.label,
          } satisfies AddressAutocompleteOption;
        } else {
          return null;
        }
      })
      .filter((option) => option !== null);
  }

  private onDestroy(): void {}
}
