import { PortalPolygonOptions } from '../types/portal-polygon';
import LatLngLiteral = google.maps.LatLngLiteral;
import { getPolygonCenter } from '../map-utils/get-polygon-center';
import { Subscription } from 'rxjs';
import { CustomHtmlMarkerModule } from '../types/custom-html-marker-module';

export class PolygonRenderer {
  private gPolygons: RenderedPolygon[] = [];

  private subs: Subscription;
  private cm: Promise<CustomHtmlMarkerModule>;

  constructor(private map: google.maps.Map) {
    this.cm = import('./custom-html-marker');
  }

  public async setPolygons(polygons: PortalPolygonOptions[]): Promise<void> {
    const cm = await this.cm;
    if (!polygons) {
      return;
    }
    const removedPolygons = this.getRemovedPolygons(this.gPolygons, polygons);
    const addedPolygons = this.getAddedPolygons(this.gPolygons, polygons, cm);
    removedPolygons.forEach((removed) => {
      removed.remove();
      const removedIndex = this.gPolygons.findIndex((gp) => gp === removed);
      this.gPolygons.splice(removedIndex, 1);
    });
    this.gPolygons.push(...addedPolygons);
    const promises = addedPolygons.map((p) => p.draw());
    await Promise.all(promises);
  }

  public destroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  private getAddedPolygons(
    previous: RenderedPolygon[],
    newPolygons: PortalPolygonOptions[],
    cm: CustomHtmlMarkerModule,
  ): RenderedPolygon[] {
    const result: RenderedPolygon[] = [];
    newPolygons
      .filter((p) => !!p)
      .forEach((newPolygon) => {
        if (!newPolygon?.shape?.compareId) {
          result.push(new RenderedPolygon(newPolygon, this.map, cm));
        } else {
          const wasAdded = previous.some((prevPolygon) => prevPolygon.compareId === newPolygon.shape.compareId);
          if (!wasAdded) {
            result.push(new RenderedPolygon(newPolygon, this.map, cm));
          }
        }
      });
    return result;
  }

  private getRemovedPolygons(previous: RenderedPolygon[], newPolygons: PortalPolygonOptions[]): RenderedPolygon[] {
    const result = [];
    previous.forEach((prevPolygon) => {
      const prevCompareId = prevPolygon.compareId;
      if (!prevCompareId) {
        result.push(prevPolygon);
      } else {
        const isPresentInTheNewBatch = newPolygons.some((newPolygon) => newPolygon.shape.compareId === prevCompareId);
        if (!isPresentInTheNewBatch) {
          result.push(prevPolygon);
        }
      }
    });
    return result;
  }
}

export class RenderedPolygon {
  public readonly compareId: string;
  private gPolygon: google.maps.Polygon;
  private polygonMarker: google.maps.Marker;
  private tooltip: any;
  private tooltipMapClickListener: google.maps.MapsEventListener;
  private hoverTooltip: any;
  private hoverTooltipListener: google.maps.MapsEventListener;
  private fixedTooltip: any;
  private borderPolyline: google.maps.Polyline;
  private mapClickListener: google.maps.MapsEventListener;
  private pointsChangedListenerSetAt: google.maps.MapsEventListener;
  private pointsChangedListenerInsertAt: google.maps.MapsEventListener;
  private mouseMoveListener: google.maps.MapsEventListener;
  private polygonClickListener: google.maps.MapsEventListener;
  private polygonMouseOutListener: google.maps.MapsEventListener;
  private polygonDragListener: google.maps.MapsEventListener;
  private closeTooltipSub: Subscription;
  constructor(
    public options: PortalPolygonOptions,
    private map: google.maps.Map,
    private cm: CustomHtmlMarkerModule,
  ) {
    this.compareId = options?.shape?.compareId;
  }

  public async draw(): Promise<void> {
    if (!this.options) {
      return;
    }
    this.gPolygon = new google.maps.Polygon({
      paths: this.options.shape.paths,
      strokeColor: this.options.shape.strokeColor,
      strokeOpacity: this.options.shape.strokeOpacity,
      strokeWeight: this.options.shape.strokeWeight,
      fillColor: this.options.shape.fillColor,
      fillOpacity: this.options.shape.fillOpacity,
      editable: this.options.shape.editable,
      draggable: this.options.shape.draggable,
      zIndex: this.options.shape.zIndex,
    });

    // pass mouse move event to map
    this.mouseMoveListener = this.gPolygon.addListener('mousemove', (event) => {
      google.maps.event.trigger(this.map, 'mousemove', event);
    });

    if (this.options.marker) {
      this.polygonMarker = new google.maps.Marker({
        ...this.options.marker,
        position: this.options.marker.position
          ? ({ lat: this.options.marker.position.lat, lng: this.options.marker.position.lng } as LatLngLiteral)
          : this.getPolygonCenter(),
      });
      this.polygonMarker.setMap(this.map);
    }

    this.polygonClickListener = this.gPolygon.addListener('click', (event) => {
      // pass click to the map
      google.maps.event.trigger(this.map, 'click', event);
      if (this.options.shape.onPolygonClicked) {
        this.options.shape.onPolygonClicked();
      }
      if (this.options.tooltip) {
        this.openOnClickTooltip(event);
      }
    });

    if (this.options.hoverTooltip) {
      this.hoverTooltipListener = this.gPolygon.addListener('mouseover', () => {
        if (this.options.shape.highlightOnActive) {
          this.applyHighlightStyle();
        }
        if (!this.hoverTooltip) {
          this.hoverTooltip = new this.cm.CustomHtmlMarker(
            this.getPolygonCenter(),
            this.map,
            '',
            '0px',
            '0px',
            (this.options.shape.zIndex || 0) + 1,
            {
              tooltip: {
                html: this.options.hoverTooltip.html,
                fix: true,
                zIndex: this.options.hoverTooltip.zIndex,
              },
              htmlListeners: this.options.hoverTooltip.htmlListeners ?? [],
            },
          );
        } else {
          this.hoverTooltip.show();
        }
      });
      this.polygonMouseOutListener = this.gPolygon.addListener('mouseout', () => {
        if (this.options.shape.highlightOnActive) {
          this.removeHighlightStyle();
        }
        if (this.hoverTooltip) {
          this.hoverTooltip.hide();
        }
      });
    }

    if (this.options.shape.borderPolyline) {
      this.borderPolyline = new google.maps.Polyline({
        ...this.options.shape.borderPolyline,
        path: this.options.shape.borderPolyline.path ?? this.options.shape.paths,
      });
      this.borderPolyline.setMap(this.map);
    }
    if (this.options.shape.onPolygonMoved) {
      this.polygonDragListener = this.gPolygon.addListener('dragend', () => {
        this.options.shape.onPolygonMoved(this.gPolygon.getPath().getArray());
      });
    }

    if (this.options.shape.onPointChanged) {
      this.pointsChangedListenerSetAt = google.maps.event.addListener(this.gPolygon.getPath(), 'set_at', () => {
        this.options.shape.onPointChanged(this.gPolygon.getPath().getArray());
      });
      this.pointsChangedListenerInsertAt = google.maps.event.addListener(this.gPolygon.getPath(), 'insert_at', () => {
        this.options.shape.onPointChanged(this.gPolygon.getPath().getArray());
      });
    }
    this.gPolygon.setMap(this.map);
  }

  public remove(): void {
    google.maps.event.clearInstanceListeners(this.gPolygon);
    this.gPolygon.setMap(null);
    if (this.polygonMarker) {
      this.polygonMarker.setMap(null);
    }
    if (this.tooltip) {
      this.tooltip.remove();
    }
    if (this.hoverTooltip) {
      this.hoverTooltip.remove();
    }
    if (this.hoverTooltipListener) {
      this.hoverTooltipListener.remove();
    }
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
    if (this.pointsChangedListenerSetAt) {
      this.pointsChangedListenerSetAt.remove();
    }
    if (this.pointsChangedListenerInsertAt) {
      this.pointsChangedListenerInsertAt.remove();
    }
    if (this.borderPolyline) {
      this.borderPolyline.setMap(null);
    }
    if (this.closeTooltipSub) {
      this.closeTooltipSub.unsubscribe();
    }
    if (this.mouseMoveListener) {
      this.mouseMoveListener.remove();
    }
    if (this.polygonClickListener) {
      this.polygonClickListener.remove();
    }
    if (this.polygonMouseOutListener) {
      this.polygonMouseOutListener.remove();
    }
    if (this.polygonDragListener) {
      this.polygonDragListener.remove();
    }
  }

  public closeTooltip(): void {
    if (this.tooltip) {
      this.tooltip.hide();
    }
  }

  private openOnClickTooltip(event: google.maps.PolyMouseEvent): void {
    if (this.tooltipMapClickListener) {
      this.tooltipMapClickListener.remove();
      this.tooltipMapClickListener = null;
    }
    if (!this.tooltip || this.options.tooltip.openAtClickPosition) {
      if (this.tooltip) {
        if (this.closeTooltipSub) {
          this.closeTooltipSub.unsubscribe();
        }
        this.tooltip.remove();
      }
      this.tooltip = new this.cm.CustomHtmlMarker(
        this.options.tooltip?.openAtClickPosition && event.latLng ? event.latLng : this.getPolygonCenter(),
        this.map,
        '',
        '0px',
        '0px',
        (this.options.shape.zIndex || 0) + 1,
        {
          tooltip: {
            html: this.options.tooltip.html,
            fix: true,
            zIndex: this.options.tooltip.zIndex,
          },
          htmlListeners: this.options.tooltip.htmlListeners ?? [],
        },
      );
      if (this.options.shape.highlightOnActive) {
        this.closeTooltipSub = this.tooltip.isVisible$.subscribe((isOpened: boolean) => {
          if (isOpened) {
            this.applyHighlightStyle();
          } else {
            this.removeHighlightStyle();
          }
        });
      }
    } else {
      this.tooltip.show();
    }
    this.tooltipMapClickListener = google.maps.event.addListenerOnce(this.map, 'click', () => {
      this.closeTooltip();
      if (this.options.shape.onPolygonClicked) {
        this.options.shape.onPolygonClicked();
      }
    });
  }

  private getPolygonCenter() {
    return getPolygonCenter(this.gPolygon);
  }

  private applyHighlightStyle(): void {
    if (this.options.shape.borderPolyline) {
      if (this.options.shape.borderPolyline.icons?.length) {
        this.borderPolyline.set(
          'icons',
          this.options.shape.borderPolyline.icons.map((iconSequence) => {
            return {
              ...iconSequence,
              icon: {
                ...iconSequence.icon,
                strokeWeight: 3,
              },
            };
          }),
        );
      } else {
        this.borderPolyline.set('strokeWeight', 4);
      }
    } else {
      this.gPolygon.set('strokeWeight', 4);
    }
  }

  private removeHighlightStyle() {
    if (this.options.shape.borderPolyline) {
      if (this.options.shape.borderPolyline.icons?.length) {
        this.borderPolyline.set('icons', this.options.shape.borderPolyline.icons);
      } else {
        this.borderPolyline.set('strokeWeight', this.options.shape.borderPolyline.strokeWeight);
      }
    } else {
      this.gPolygon.set('strokeWeight', this.options.shape.strokeWeight);
    }
  }
}
