import { NgModule } from '@angular/core';
import { InputAddressCustomPanelComponent } from './input-address-custom-panel/input-address-custom-panel.component';
import { GoogleAddressAutocompleteCustomUiPanelComponent } from './google-address-autocomplete-custom-ui-panel/google-address-autocomplete-custom-ui-panel.component';
import { GoogleAddressAutocompleteCustomUiInputDirective } from './directives/google-address-autocomplete-custom-ui-input.directive';
import { GoogleAddressAutocompleteInputDirective } from '../../google-map/google-place-autocomplete/google-ui/google-address-autocomplete-input.directive';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IconCloseModule } from '../../icons/icon-close/icon-close.module';
import { ComponentsModule } from '../../components/components.module';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    IconCloseModule,
    ComponentsModule,
  ],
  exports: [
    InputAddressCustomPanelComponent,
    GoogleAddressAutocompleteCustomUiPanelComponent,
    GoogleAddressAutocompleteCustomUiInputDirective,
    GoogleAddressAutocompleteInputDirective,
  ],
  declarations: [
    InputAddressCustomPanelComponent,
    GoogleAddressAutocompleteCustomUiPanelComponent,
    GoogleAddressAutocompleteCustomUiInputDirective,
    GoogleAddressAutocompleteInputDirective,
  ],
  providers: [],
})
export class InputAddressCustomModule {}
