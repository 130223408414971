import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { SmartInputAddress } from '../../models';
import { Address, MapBounds } from '@rootTypes';
import { Validators } from '@angular/forms';

@Component({
  selector: 'wp-smart-input-address',
  templateUrl: './smart-input-address.component.html',
  styleUrls: ['./smart-input-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputAddressComponent {
  @Input() model: SmartInputAddress;
  @Input() currentMapBounds: MapBounds;
  @Input() districtId: string | null;
  @Output() inputEvent = new EventEmitter<string>();
  @Output() valueChangedByUser = new EventEmitter<Address | null>();
  protected readonly Validators = Validators;
}
