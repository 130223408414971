<div class="map-content">
  <div class="recenter-button-wrap">
    <wp-recenter-button (click)="onRecenterClik()" />
  </div>

  <div class="layer-controls">
    <div *ngIf="isAnnotatedRideTraces" class="annotated-trace-selector-wrap">
      <wp-annotated-trace-selector
        [selected]="rideTraceDisplayMode"
        (selectedChanged)="rideTraceDisplayModeChanged.emit($event)"
      ></wp-annotated-trace-selector>
    </div>
    <div *ngIf="isShowRidePlannedPathToggle" class="show-default-route-toggle-wrap">
      <wp-show-default-route-toggle
        [isSelected]="isRidePlannedPathToggleSelected"
        (toggled)="ridePlannedPathToggleChanged.emit()"
      ></wp-show-default-route-toggle>
    </div>
  </div>

  <div #map class="map-div"></div>

  <ng-container *ngIf="mapTilesLoading$ | async">
    <div class="map-tiles-loading-mask">
      <wp-section-spinner [sectionHeight]="'40px'" [spinnerSize]="40" />
    </div>
  </ng-container>
</div>
