import { createAction, props } from '@ngrx/store';
import { RouteGroup, RouteGroupScheduleInfo } from '@apiEntities/route-group/route-group';
import { DateRange, WpError } from '@rootTypes';
import { GetRouteGroupConfigResponse } from '../../../api/endpoints/get-route-group-config';

/**
 * Get route group
 */
export const getRouteGroupRequestedFromRouteGroupDetails = createAction(
  '[RouteGroupDetails]: API Get route group requested',
  props<{
    routeGroupId: string;
    dateRange: DateRange;
    scheduleVersion: string;
    districtProgramIds?: string[];
    routeGroupAssignmentType?: 'DRIVER' | 'MONITOR';
  }>(),
);

export const getRouteGroupRequestedFromRouteGroupDetailsOnUpdate = createAction(
  '[RouteGroupDetails]: API Get route group requested on update',
  props<{
    routeGroupId: string;
    dateRange: DateRange;
    districtProgramIds?: string[];
    scheduleVersion: string;
    routeGroupAssignmentType?: 'DRIVER' | 'MONITOR';
  }>(),
);

export const getRouteGroupRequestedFromRouteGroupList = createAction(
  '[RouteGroupList]: API Get route group requested',
  props<{
    routeGroupId: string;
    dateRange: DateRange;
    districtProgramIds?: string[];
    scheduleVersion: string;
    routeGroupAssignmentType?: 'DRIVER' | 'MONITOR';
  }>(),
);

export const getRouteGroupRequestedOnVendorYardUpdate = createAction(
  '[RouteGroupList]: API Get route group requested on vendor/yard update',
  props<{
    routeGroupId: string;
    dateRange: DateRange;
    districtProgramIds?: string[];
    scheduleVersion: string;
    routeGroupAssignmentType?: 'DRIVER' | 'MONITOR';
  }>(),
);

export const getRouteGroupRequestedOnEdit = createAction(
  '[RouteGroupList]: API Get route group requested on edit',
  props<{
    routeGroupId: string;
    dateRange: DateRange;
    districtProgramIds?: string[];
    scheduleVersion: string;
    routeGroupAssignmentType?: 'DRIVER' | 'MONITOR';
  }>(),
);

export const getRouteGroupRequestedOnScheduleUpdate = createAction(
  '[RouteGroupList]: API Get route group requested on schedule update',
  props<{
    routeGroupId: string;
    dateRange: DateRange;
    districtProgramIds?: string[];
    scheduleVersion: string;
    routeGroupAssignmentType?: 'DRIVER' | 'MONITOR';
  }>(),
);

export const getRouteGroupSuccess = createAction(
  '[RouteGroupDataEffects]: API Get route group success',
  props<{ routeGroup: RouteGroup }>(),
);

export const getRouteGroupFailed = createAction(
  '[RouteGroupDataEffects]: API Get route group failed',
  props<{ routeGroupId: string; error: WpError }>(),
);

export const getRouteGroupActions = [
  getRouteGroupRequestedFromRouteGroupList,
  getRouteGroupRequestedFromRouteGroupDetailsOnUpdate,
  getRouteGroupRequestedOnVendorYardUpdate,
  getRouteGroupRequestedOnEdit,
  getRouteGroupRequestedOnScheduleUpdate,
];

/**
 * Get schedule versions by week
 */
export const getRouteGroupScheduleVersionsByWeekFromRouteGroupDetails = createAction(
  '[RouteGroupDetails]: Get route group schedule versions by week',
  props<{ routeGroupId: string; week: DateRange }>(),
);

export const getRouteGroupScheduleVersionsByWeekSuccess = createAction(
  '[RouteGroupDataEffects]: Get route group schedule versions by week success',
  props<{ routeGroupId: string; week: DateRange; routeGroup: RouteGroup }>(),
);

export const getRouteGroupScheduleVersionsByWeekFailed = createAction(
  '[RouteGroupDataEffects]: Get route group schedule versions by week failed',
  props<{ routeGroupId: string; week: DateRange; error: WpError }>(),
);

/**
 * Get route group schedule
 */
export const getRouteGroupScheduleRequestedFromRouteGroupDetails = createAction(
  '[RouteGroupDetails]: Get route group schedule requested',
  props<{ routeGroupId: string; routeGroupScheduleId: string; routeGroupAssignmentType: 'DRIVER' | 'MONITOR' }>(),
);

export const getRouteGroupScheduleSuccess = createAction(
  '[RouteGroupDataEffects]: Get route group schedule success',
  props<{ routeGroupSchedule: RouteGroupScheduleInfo }>(),
);

export const getRouteGroupScheduleFailed = createAction(
  '[RouteGroupDataEffects]: Get route group schedule failed',
  props<{ routeGroupScheduleId: string; error: WpError }>(),
);

/**
 * Toggle sync with activities
 */

export const routeGroupActivitySyncFlagToggled = createAction(
  '[RouteGroupComponent]: Activity sync flag toggled',
  props<{ routeGroupId: string; enabled: boolean }>(),
);

export const setRouteGroupActivitySyncFlagRequested = createAction(
  '[RouteGroupEffects]: API setRouteGroupActivitySyncFlag requested',
  props<{ routeGroupId: string; enabled: boolean }>(),
);

export const setRouteGroupActivitySyncFlagSuccess = createAction(
  '[RouteGroupEffects]: API setRouteGroupActivitySyncFlag success',
  props<{ routeGroupId: string; enabled: boolean }>(),
);

export const setRouteGroupActivitySyncFlagFailed = createAction(
  '[RouteGroupEffects]: API setRouteGroupActivitySyncFlag failed',
  props<{ routeGroupId: string; error: WpError }>(),
);

export const getRouteGroupConfigRequested = createAction(
  '[RouteGroupDetailsEffects]: Get route group config requested',
  props<{ districtId: string }>(),
);

export const getRouteGroupConfigSuccess = createAction(
  '[RouteGroupDetailsEffects]: Get route group config success',
  props<{ districtId: string; config: GetRouteGroupConfigResponse }>(),
);

export const getRouteGroupConfigFailed = createAction(
  '[RouteGroupDetailsEffects]: Get route group config failed',
  props<{ districtId: string; error: WpError }>(),
);
