import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wp-show-default-route-toggle',
  template: `
    <button
      class="map-button"
      matTooltip="Planned path"
      [matTooltipPosition]="'before'"
      [ngClass]="{ selected: isSelected }"
      (click)="toggled.emit()"
    >
      <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1557_138)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.5 19.8979C19.5 19.4837 19.8358 19.1479 20.25 19.1479C20.6642 19.1479 21 19.4837 21 19.8979V20.6479H25V19.8979C25 19.4837 25.3358 19.1479 25.75 19.1479C26.1642 19.1479 26.5 19.4837 26.5 19.8979V20.6479C27.3284 20.6479 28 21.3195 28 22.1479V29.1479C28 29.9764 27.3284 30.6479 26.5 30.6479H19.5C18.6716 30.6479 18 29.9764 18 29.1479V22.1479C18 21.3195 18.6716 20.6479 19.5 20.6479V19.8979ZM20 23.6479V22.6479H26V23.6479H20ZM20 25.1479V28.6479H26V25.1479H20Z"
            fill="#959597"
            class="icon-path-fill"
          />
          <path
            d="M7.38479 24.7345H16.1964V27.2884H7.38479C5.61407 27.2884 4.11578 26.6584 2.87287 25.4155C1.62997 24.1726 1 22.6573 1 20.9036C1 19.1499 1.62997 17.6346 2.87287 16.3917C4.11578 15.1488 5.6311 14.5188 7.38479 14.5188H22.4109C23.4665 14.5188 24.3689 14.1443 25.118 13.3951C25.8672 12.646 26.2418 11.7436 26.2418 10.688C26.2418 9.63234 25.8672 8.72996 25.118 7.98081C24.3689 7.23166 23.4665 6.85709 22.4109 6.85709H10.1941C9.81952 7.8446 9.24063 8.66185 8.42338 9.30885C7.60613 9.95584 6.70375 10.2623 5.68218 10.2623C4.3882 10.2623 3.2815 9.8026 2.36209 8.90022C1.44268 7.98081 1 6.89114 1 5.58013C1 4.26912 1.45971 3.17945 2.36209 2.26004C3.26447 1.34063 4.3882 0.897949 5.68218 0.897949C6.70375 0.897949 7.60613 1.22145 8.42338 1.85141C9.24063 2.48138 9.81952 3.29863 10.1941 4.30317H22.4109C24.1816 4.30317 25.6799 4.93314 26.9228 6.17604C28.1657 7.41895 28.7957 8.93427 28.7957 10.688C28.7957 12.4416 28.1657 13.957 26.9228 15.1999C25.6799 16.4428 24.1646 17.0727 22.4109 17.0727H7.38479C6.32917 17.0727 5.42679 17.4473 4.67764 18.1965C3.92849 18.9456 3.55392 19.848 3.55392 20.9036C3.55392 21.9592 3.92849 22.8616 4.67764 23.6108C5.42679 24.3599 6.32917 24.7345 7.38479 24.7345ZM7.81044 5.58013C7.81044 4.98421 7.60613 4.49046 7.1975 4.0648C6.78888 3.65618 6.27809 3.45186 5.68218 3.45186C5.08627 3.45186 4.59251 3.65618 4.16686 4.0648C3.75823 4.47343 3.55392 4.98421 3.55392 5.58013C3.55392 6.17604 3.75823 6.6698 4.16686 7.09545C4.57548 7.50408 5.08627 7.70839 5.68218 7.70839C6.27809 7.70839 6.77185 7.50408 7.1975 7.09545C7.60613 6.68682 7.81044 6.17604 7.81044 5.58013Z"
            fill="#959597"
            class="icon-path-fill"
          />
        </g>
        <defs>
          <clipPath id="clip0_1557_138">
            <rect width="30" height="30" fill="white" transform="translate(0 0.897949)" />
          </clipPath>
        </defs>
      </svg>
    </button>
  `,
  styleUrl: './show-default-route-toggle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowDefaultRouteToggleComponent {
  @Input() isSelected: boolean;
  @Output() toggled = new EventEmitter<void>();
}
