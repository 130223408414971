import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import * as fromApi from '../../api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ZumGeocoderService {
  constructor(private api: ApiService) {}

  public geocode(request: fromApi.geocode.GeocodeRequest): Observable<fromApi.geocode.GeocodeResponse> {
    return this.api.geocode(request);
  }

  public getAddressInfoByPlaceId(
    request: fromApi.getAddressInfoByPlaceId.GetAddressInfoByPlaceIdRequest,
  ): Observable<fromApi.getAddressInfoByPlaceId.GetAddressInfoByPlaceIdResponse> {
    return this.api.getAddressInfoByPlaceId(request);
  }
}
