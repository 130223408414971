import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Address } from '@rootTypes';
import { GooglePlaceAutocompleteCustomUIStore } from '../store/google-place-autocomplete-custom-ui-store';
import { FormControlDirective } from '@angular/forms';

@Directive({
  selector: '[wpGoogleAddressAutocompleteCustomUIInput]',
})
export class GoogleAddressAutocompleteCustomUiInputDirective implements OnChanges, AfterViewInit {
  @Input() selectedAddress: Address | null = null;
  @Input() districtId: string | null = null;
  private readonly control: FormControlDirective;
  constructor(
    private store: GooglePlaceAutocompleteCustomUIStore,
    private el: ElementRef,
    private cd: ChangeDetectorRef,
  ) {
    this.control = inject(FormControlDirective, { optional: true });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedAddress && changes.selectedAddress?.firstChange) {
      this.updateSelectedAddress();
    }

    if (changes.districtId) {
      this.setDistrictId();
    }
  }

  ngAfterViewInit(): void {
    this.updateSelectedAddress();
    this.setDistrictId();
  }

  @HostListener('input', ['$event']) public onInput(event: Event) {
    this.store.onInputValueChanged((event.target as HTMLInputElement)?.value);
  }

  private setDistrictId(): void {
    this.store.onDistrictIdSet(this.districtId);
  }

  private updateSelectedAddress(): void {
    const current = this.store.getLastSelectedAddress();
    const next = this.selectedAddress;

    if (
      current?.geometry?.location?.lat !== next?.geometry?.location?.lat ||
      current?.geometry?.location?.lng !== next?.geometry?.location?.lng
    ) {
      this.store.setLastSelectedAddress(next);
      if (!this.store.getLastSelectedAddress()) {
        this.store.resetSearch();
      }

      if (this.control) {
        this.control.valueAccessor.writeValue(this.store.getLastSelectedAddress()?.formatted_address ?? null);
      }

      if (this.el?.nativeElement) {
        this.el.nativeElement.value = this.store.getLastSelectedAddress()?.formatted_address ?? null;
      }

      this.cd.detectChanges();
    }
  }
}
