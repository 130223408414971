<wp-entity-filter
  #entityFilter
  [searchByType]="searchByType"
  [initialDisplayValue]="initialDisplayValue"
  [vendorIds]="vendorIds"
  [yardIds]="yardIds"
  [extraFilters]="extraFilters"
  [operatorIds]="operatorIds"
  [placeholder]="label"
  [optionTemplate]="optionTemplate"
  [disabled]="disabled"
  [resetSelectedOnInputClear]="true"
  [fillInputOnSelect]="true"
  [entityStatus]="entityStatus"
  (selected)="onOptionSelect($event)"
></wp-entity-filter>

<ng-template #optionTemplate let-entity="entity">
  <div class="wp-driver-select-option">
    <div class="driver-name">{{entity.label}}</div>
  </div>
</ng-template>
