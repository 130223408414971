import { PortalRouteTracePoint } from '../interfaces';
import { defaultTracePolylineOptions } from './car-mover/polyline-options';
import { drawArrowIcon } from './car-mover/utils';
import { Polyline, PolylineDrawer } from './polyline-drawer';

/**
 * Renders driving route between the points
 */
export class DrivingRouteRenderer {
  private directionsService?: google.maps.DirectionsService;
  private directionsRenderer?: google.maps.DirectionsRenderer;
  private polylineDrawer?: PolylineDrawer;
  private defaultPolylineOverride: Partial<Polyline> = {};

  constructor(
    private map: google.maps.Map,
    private isCalculateDirectionsNeeded: boolean,
    private points?: PortalRouteTracePoint[],
  ) {
    if (this.isCalculateDirectionsNeeded) {
      this.initDirectionServices();
    } else {
      this.polylineDrawer = new PolylineDrawer(this.map);
    }
  }

  public setPoints(points: PortalRouteTracePoint[], defaultPolylineOverride?: Partial<Polyline>): void {
    this.points = points;
    this.defaultPolylineOverride = defaultPolylineOverride ?? {};
    if (this.isCalculateDirectionsNeeded) {
      this.directionsRenderer?.setMap(null);
    } else {
      this.polylineDrawer?.setPolylines([]);
    }
    void this.showRoute();
  }

  private async showRoute(): Promise<void> {
    if (!this.points?.length) {
      return;
    }
    if (this.isCalculateDirectionsNeeded) {
      await this.constructGoogleRoute();
      this.directionsRenderer?.setMap(this.map);
    } else {
      const polyline = this.getDrivingRoutePolyline();
      this.polylineDrawer?.setPolylines([polyline]);
    }
  }

  public hideRoute(): void {
    if (this.isCalculateDirectionsNeeded) {
      this.directionsRenderer?.setMap(null);
    } else {
      this.polylineDrawer?.setPolylines([]);
    }
  }

  private initDirectionServices() {
    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      polylineOptions: {
        ...defaultTracePolylineOptions,
        icons: drawArrowIcon(true),
      },
    });
  }

  private async constructGoogleRoute(): Promise<void> {
    const locations = this.getLocations();
    const intermediateLocations = locations.slice(1, locations.length - 1);
    const waypoints = intermediateLocations.map((location) => {
      return {
        location,
        stopover: false,
      };
    });
    const directionsRequest: google.maps.DirectionsRequest = {
      origin: locations[0],
      destination: locations[locations.length - 1],
      travelMode: google.maps.TravelMode.DRIVING,
    };
    if (waypoints.length > 0) {
      directionsRequest['waypoints'] = waypoints;
    }
    const directions = await this.getDirections(directionsRequest);
    return this.setDirections(directions);
  }

  private async getDirections(directionOptions: google.maps.DirectionsRequest): Promise<google.maps.DirectionsResult> {
    return new Promise<google.maps.DirectionsResult>((resolve, reject) => {
      this.directionsService.route(directionOptions, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          resolve(result);
        } else {
          reject({ result, status });
        }
      });
    });
  }

  private setDirections(directions: google.maps.DirectionsResult): void {
    return this.directionsRenderer.setDirections(directions);
  }

  private getLocations(): google.maps.LatLng[] {
    return this.points.map((point) => {
      return new google.maps.LatLng(point.lat, point.lng);
    });
  }

  private getDrivingRoutePolyline(): Polyline {
    return {
      ...defaultTracePolylineOptions,
      id: 'DrivingRoutePolyline',
      path: this.points.map((point) => ({ lat: point.lat, lng: point.lng })),
      icons: drawArrowIcon(true),
      zIndex: 4,
      ...this.defaultPolylineOverride,
    };
  }
}
