export * from '../../../../data/rides';

export * from '../../../../types/entities/common';
export * from '../../../../types/entities/ride';
export * from '../../../../types/entities/driver';
export * from '../../../../types/entities/vehicles';

import * as utils from '../../../../types/utils/common';
export { utils };

import * as rideStatus from '../../../../types/utils/ride';
export { rideStatus };

export enum RideCardType {
  RIDE_BY_STUDENT = 'RIDE_BY_STUDENT',
  RIDE_WITH_DRIVER = 'RIDE_WITH_DRIVER',
  DEFAULT = 'DEFAULT',
}

export interface TabRideListViewConfig {
  rideCardType: RideCardType;
}

export interface RideMapMessage {
  text: string;
  type: 'info' | 'error';
}

export interface RideMapLegendItem {
  label: string;
  color: string;
}

export enum RidePolylineTypes {
  PLANNED_PATH_SCHEDULED = 'PLANNED_PATH_SCHEDULED',
  RIDE_TRACE = 'RIDE_TRACE',
  ANNOTATED_TRACE = 'ANNOTATED_TRACE',
  PLANNED_PATH_COMPLETED = 'PLANNED_PATH_COMPLETED',
}

type RidePolylineConfig = {
  colorHEX: string;
};

export const ridePolylineConfigs: { [K in RidePolylineTypes]: RidePolylineConfig } = {
  [RidePolylineTypes.PLANNED_PATH_SCHEDULED]: {
    colorHEX: '#BBCCE4',
  },
  [RidePolylineTypes.RIDE_TRACE]: {
    colorHEX: '#BBCCE4',
  },
  [RidePolylineTypes.ANNOTATED_TRACE]: {
    colorHEX: '#C8DBB7',
  },
  [RidePolylineTypes.PLANNED_PATH_COMPLETED]: {
    colorHEX: '#D9BBBB',
  },
};
