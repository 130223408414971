import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RideGoogleMapComponent } from './ride-google-map/ride-google-map.component';
import { StreetViewComponent } from './street-view/street-view.component';
import { RecenterButtonComponent } from './recenter-button/recenter-button.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { StreetViewPanoService } from './street-view-pano.service';
import { ComponentsModule } from '../components/components.module';
import { GoogleMapComponent } from './google-map/google-map.component';
import { MatAutocomplete, MatOption } from '@angular/material/autocomplete';
import { TypographyModule } from '../typography/typography.module';
import { AnnotatedTraceSelectorComponent } from './annotated-trace-selector/annotated-trace-selector.component';
import { ShowDefaultRouteToggleComponent } from './show-default-route-toggle/show-default-route-toggle.component';
import { InputAddressCustomModule } from '../form-controls/input-address-custom/input-address-custom.module';

const components = [RideGoogleMapComponent, StreetViewComponent];

@NgModule({
  declarations: [
    ...components,
    RecenterButtonComponent,
    GoogleMapComponent,
    AnnotatedTraceSelectorComponent,
    ShowDefaultRouteToggleComponent,
  ],
  imports: [
    CommonModule,
    TooltipModule,
    ComponentsModule,
    MatOption,
    MatAutocomplete,
    TypographyModule,
    InputAddressCustomModule,
  ],
  providers: [StreetViewPanoService],
  exports: [...components, GoogleMapComponent],
})
export class GoogleMapModule {}
