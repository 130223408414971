import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { Address, MapBounds } from '@rootTypes';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { GooglePlaceAutocompleteCustomUIStore } from '../store/google-place-autocomplete-custom-ui-store';

@Component({
  selector: 'wp-input-address-custom-panel',
  providers: [GooglePlaceAutocompleteCustomUIStore],
  template: `
    <!--matPrefix and matSuffix are for consistent layout-->
    <mat-form-field class="wp-address-input" [hideRequiredMarker]="true">
      <mat-label *ngIf="!!label">{{ label }}</mat-label>
      <div matPrefix></div>
      <input
        matInput
        wpGoogleAddressAutocompleteCustomUIInput
        [formControl]="control"
        [required]="required"
        [disabled]="control.disabled"
        [districtId]="districtId"
        [selectedAddress]="selectedValue$ | async"
        [matAutocomplete]="panel.autocomplete"
        [matAutocompleteDisabled]="!panel.autocomplete"
        (input)="onUserInput()"
        #searchInput
      />
      <div matSuffix>
        <div *ngIf="control?.value && !control.disabled" class="suffix">
          <wp-icon-close (click)="searchInput.value = null; onCrossClicked()" [size]="'small'"></wp-icon-close>
        </div>
      </div>
      <!--      TODO: Implement error states, when this input control is used with forms i.e. required and "pick address from the list". We'll need to use ErrorStateMatcher. -->
      <wp-google-address-autocomplete-custom-ui-panel
        #panel
        (addressSelected)="onSelectedAddress($event)"
      ></wp-google-address-autocomplete-custom-ui-panel>
      <mat-error *ngIf="control.hasError('required') && !control.hasError('notPickedFromList')">
        Required field
      </mat-error>
      <mat-error *ngIf="control.hasError('notPickedFromList')">
        Address not recognized, pick an address from the list
      </mat-error>
    </mat-form-field>
  `,
  styleUrl: './input-address-custom-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputAddressCustomPanelComponent implements OnInit, OnChanges {
  @Input() public control: FormControl<Address>;
  @Input() public controlStateChange?: Observable<void>;
  @Input() public label: string;
  @Input() public districtId: string | null;
  @Input() public required: boolean;
  @Input() public displayControl: UntypedFormControl = new UntypedFormControl();
  // provide map bounds to bias search towards the region selected on the map
  @Input() public searchBias: MapBounds;
  @Output() public inputEvent = new EventEmitter<string>();
  @Output() public valueChangedByUser = new EventEmitter<Address>();
  public selectedValue$: Observable<Address | null>;
  constructor(private store: GooglePlaceAutocompleteCustomUIStore) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchBias) {
      this.store.setGoogleAutocompleteBias(this.searchBias);
    }
  }

  ngOnInit(): void {
    this.selectedValue$ = this.control.valueChanges.pipe(startWith(this.control.value));
  }

  onCrossClicked(): void {
    this.store.resetSearch();
    this.control.setValue(null, { emitEvent: false });
  }

  public onUserInput(): void {
    this.control.setErrors({ notPickedFromList: true });
    if (this.control.pristine) {
      this.control.markAsDirty();
    }
    this.inputEvent.emit(this.control.value?.formatted_address);
    this.valueChangedByUser.emit(undefined);
  }

  public onSelectedAddress(address: Address): void {
    this.control.setValue(address);
    this.valueChangedByUser.emit(address);
    this.control.setErrors(null);
  }

  protected readonly Validators = Validators;
}
