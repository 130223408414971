import { AddressV2, CustomAddress } from '@apiEntities/common/address-v2';
import { Address } from '@rootTypes';

export const addressToApiAddress = (address: AddressV2 | CustomAddress, overrideFormattedAddress?: string): Address => {
  return {
    address_components: [
      { long_name: address.streetNumber, short_name: address.streetNumber, types: ['street_number'] },
      { long_name: address.streetName, short_name: address.streetName, types: ['route'] },
      { long_name: address.city, short_name: address.city, types: ['locality'] },
      { long_name: address.state, short_name: address.state, types: ['administrative_area_level_1'] },
      { long_name: address.postalCode, short_name: address.postalCode, types: ['postal_code'] },
    ],
    formatted_address: overrideFormattedAddress,
    geometry: {
      location: {
        lat: address.location.lat,
        lng: address.location.lng,
      },
    },
    name: address.name,
  };
};
