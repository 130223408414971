import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { PortalRouteTracePoint } from '../../../../types/entities/ride';
import { GoogleMapState } from '../../interfaces';
import { MapLocation } from '../../../../types';
import { getLocationsHash } from '../get-locations-hash';
import { DrivingRouteRenderer } from '../driving-route-renderer';
import { CustomRouteRenderer } from '../custom-route-renderer';
import { RideTraceDisplayMode } from '../../types/ride-google-map';
import { GetAnnotatedTracesForRideResponse } from '../../../../api/endpoints/get-annotated-traces-for-ride';
import { AnnotatedRideTraceRenderer } from '../annotated-ride-trace-renderer';
import { ridePolylineConfigs, RidePolylineTypes } from '../../../../features/common/rides-common/tab-rides-list/types';

export class RouteDisplayMode {
  // draws default driving route between points
  private drivingRouteRenderer: DrivingRouteRenderer;
  private annotatedTraceRenderer: AnnotatedRideTraceRenderer;
  // draws actual route using the points
  private subscriptions: Subscription = new Subscription();

  constructor(
    private state$: BehaviorSubject<GoogleMapState>,
    private customRouteRenderer: CustomRouteRenderer,
    private isCalculateDirectionsNeeded: boolean,
  ) {
    this.state$
      .asObservable()
      .pipe(
        filter((s) => !!s.map),
        take(1),
      )
      .subscribe(() => this.init());
  }

  public dispose(): void {
    this.subscriptions.unsubscribe();
  }

  public reset(): void {
    if (this.customRouteRenderer) {
      this.customRouteRenderer.setPoints([]);
    }
  }

  private init(): void {
    this.drivingRouteRenderer = new DrivingRouteRenderer(this.state$.value.map, this.isCalculateDirectionsNeeded);
    this.annotatedTraceRenderer = new AnnotatedRideTraceRenderer(this.state$.value.map);
    this.setDisplayRouteTraceSubscriptions();
  }

  private setDisplayRouteTraceSubscriptions(): void {
    const getTracePoints$ = this.state$.asObservable().pipe(
      map((state) => state.displayTracePoints),
      distinctUntilChanged(this.locationsChanged.bind(this)),
    );
    const defaultDrivingRoutePoints$ = this.state$.asObservable().pipe(
      map((state) => state.displayDefaultDrivingRoutePoints),
      distinctUntilChanged(this.locationsChanged.bind(this)),
    );
    const isDisplayDefaultDrivingRoute$ = this.state$.asObservable().pipe(
      map((state) => state.isDisplayDefaultDrivingRoute),
      distinctUntilChanged(),
    );
    const isDisplayTraceHistory$ = this.state$.asObservable().pipe(
      map((state) => state.isShowTraceHistory),
      distinctUntilChanged(),
    );

    const traceDisplayMode$ = this.state$.asObservable().pipe(
      map((s) => s.rideTraceDisplayMode),
      distinctUntilChanged(),
    );

    const annotatedRideTraces$ = this.state$.asObservable().pipe(
      map((s) => s.annotatedRideTraces),
      distinctUntilChanged(),
    );

    const isRidePlannedPathToggleSelected$ = this.state$.asObservable().pipe(
      map((s) => s.isRidePlannedPathToggleSelected),
      distinctUntilChanged(),
    );

    const displayRouteSubscription = combineLatest([
      getTracePoints$,
      defaultDrivingRoutePoints$,
      isDisplayDefaultDrivingRoute$,
      isDisplayTraceHistory$,
      traceDisplayMode$,
      annotatedRideTraces$,
      isRidePlannedPathToggleSelected$,
    ]).subscribe(
      ([
        tracePoints,
        defaultRoutePoints,
        isDisplayDefault,
        isDisplayTraceHistory,
        traceDisplayMode,
        annotatedRideTraces,
        isRidePlannedToggleSelected,
      ]) =>
        this.onDisplayRouteTraceChanged(
          tracePoints,
          defaultRoutePoints,
          isDisplayDefault,
          isDisplayTraceHistory,
          traceDisplayMode,
          annotatedRideTraces,
          isRidePlannedToggleSelected,
        ),
    );
    this.subscriptions.add(displayRouteSubscription);
  }

  private onDisplayRouteTraceChanged(
    routeTracePoints: PortalRouteTracePoint[],
    defaultDrivingRoutePoints: PortalRouteTracePoint[],
    isDisplayDefaultDrivingRoute: boolean,
    isDisplayTraceHistory: boolean,
    traceDisplayMode: RideTraceDisplayMode,
    annotatedTraces: GetAnnotatedTracesForRideResponse,
    isRidePlannedToggleSelected: boolean,
  ): void {
    this.drivingRouteRenderer.hideRoute();
    this.customRouteRenderer.hide();
    this.annotatedTraceRenderer.hide();
    if (isDisplayDefaultDrivingRoute) {
      this.drivingRouteRenderer.setPoints(defaultDrivingRoutePoints);
    } else if (isDisplayTraceHistory) {
      if (traceDisplayMode === RideTraceDisplayMode.TRACE) {
        this.customRouteRenderer.setPoints(routeTracePoints);
      } else if (traceDisplayMode === RideTraceDisplayMode.ANNOTATED) {
        this.annotatedTraceRenderer.setAnnotatedTraces(annotatedTraces);
      }
    }
    if (isRidePlannedToggleSelected) {
      this.drivingRouteRenderer.setPoints(defaultDrivingRoutePoints, {
        strokeColor: ridePolylineConfigs[RidePolylineTypes.PLANNED_PATH_COMPLETED].colorHEX,
        zIndex: 3,
        tooltip: {
          htmlGetter: () => ``,
          width: '0px',
          height: '0px',
          zIndex: 8,
          followCursor: true,
        },
      });
    }
  }

  private locationsChanged(prev: MapLocation[], curr: MapLocation[]): boolean {
    return getLocationsHash(prev) === getLocationsHash(curr);
  }
}
