// Production environment configuration
export const environment = {
  isCustomAddressManagementEnabled: false,
  isMockAPI: false,
  isStoreDevToolsEnabled: false,
  isGeofenceSearchEnabled: false,
  isVendorEmployeeManagementEnabled: true,
  isAnnotatedRideTraceEnabled: true,
  isCampusLocationDeactivation: false,
  isRestrictionsEnabled: false,
};
