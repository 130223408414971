import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'wp-title-six',
  template: `
    <h6 class="title-6">
      <ng-content></ng-content>
    </h6>
  `,
  styleUrl: './title-six.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleSixComponent {}
